<template>
  <div class="saleSettlement">
    <h1>销售结算单</h1>
    <el-form :inline="true">
      <el-form-item label="到货日期">
        <el-date-picker v-model="searchParam.settlementIntervalFromDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del">删除</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-text type="danger" size="small">* 合计数：当前页面显示数量的合计数</el-text>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="settlementIntervalFrom" label="到货日期" width="100" show-overflow-tooltip />
      <el-table-column prop="settlementIntervalTo" label="至" width="100" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="saleAmount" label="销售金额" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="settlementAmount" label="已结算" width="120">
        <template #default="scope">
          <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="scope.row.settlementAmount" @change="save(scope.row, 'settlementAmount')" />
        </template>
      </el-table-column>
      <el-table-column label="结余" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>
            {{ math.formatNumber(math.plus(scope.row.saleAmount, -scope.row.settlementAmount)) }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="truckQuantity" label="车数" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.truckQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sampleQuantity" label="销售数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sampleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column label="平均单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>
            {{ math.formatNumber(math.halfUp(scope.row.saleAmount / scope.row.sampleQuantity, 2)) }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="quanshui" label="平均全水" width="120" show-overflow-tooltip />
      <el-table-column prop="neishui" label="平均内水" width="120" show-overflow-tooltip />
      <el-table-column prop="huifen" label="平均灰分" width="120" show-overflow-tooltip />
      <el-table-column prop="huifafen" label="平均挥发分" width="120" show-overflow-tooltip />
      <el-table-column prop="quanliu" label="平均全硫" width="120" show-overflow-tooltip />
      <el-table-column prop="gudingtan" label="平均固定碳" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangMj" label="平均热·兆焦" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangKc" label="平均热·大卡" width="120" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>

</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
})

const show = reactive({
  selected: [],
})

const searchParam = reactive({})
const now = new Date()
const begin = new Date();
const end = new Date();
if (now.getDate() >= 25) {
  begin.setDate(25)
  end.setMonth(end.getMonth() + 1)
  end.setDate(24)
} else {
  begin.setMonth(end.getMonth() - 1)
  begin.setDate(25)
  end.setDate(24)
}

const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.settlementIntervalFromDates = [begin, end]
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.settlementIntervalFromDates && searchParam.settlementIntervalFromDates.length == 2) {
    params.settlementIntervalFromGe = util.parseTime(searchParam.settlementIntervalFromDates[0], '{y}-{m}-{d}') + 'T00:00:00'
    let settlementIntervalFromLt = new Date(searchParam.settlementIntervalFromDates[1])
    settlementIntervalFromLt.setDate(settlementIntervalFromLt.getDate() + 1)
    params.settlementIntervalFromLt = util.parseTime(settlementIntervalFromLt, '{y}-{m}-{d}') + 'T00:00:00'
  }
  api.get('/backend/saleSample/settlementPage', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
  })
}
commitFindList()

const summary = () => {
  const list = data.list
  let saleAmount = 0
  let truckQuantity = 0
  let sampleQuantity = 0
  list.forEach(item => {
    saleAmount = math.plus(saleAmount, item.saleAmount)
    truckQuantity = math.plus(truckQuantity, item.truckQuantity)
    sampleQuantity = math.plus(sampleQuantity, item.sampleQuantity)
  })
  return ['合计', '', '', '', '', math.formatNumber(saleAmount), '', '', math.formatNumber(truckQuantity), math.formatNumber(sampleQuantity)]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const save = (row, cname) => {
  const param = { id: row.id }
  param[cname] = row[cname]
  console.log(param)
  api.post('/backend/saleSample/setSettlementById', param).then(() => {
    commitFindList()
    ElMessage.success('更新成功')
  })
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(saleSample => saleSample.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/saleSample/delSettlementById', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

</script>

<style lang="less"></style>